import { navigate, useIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import LockSuccess from '../../assets/images/lock-success.svg'
import { Layout } from '../../components/auth/Layout'
import { SimpleContent } from '../../components/auth/SimpleContent'
import { AccountType } from '../../requests'
import { routes } from '../../routes'

const SubjectsChangePasswordSuccessPage = () => {
  const intl = useIntl()
  const intlChangePasswordSuccess = (scopedId: string) =>
    intl.formatMessage({ id: `auth.change_password_success.${scopedId}` })

  const onSubmit = () => {
    navigate(routes.signIn(AccountType.Subject))
    return Promise.resolve()
  }

  return (
    <Layout accountType={AccountType.Subject}>
      <SimpleContent
        title={intlChangePasswordSuccess('title')}
        subtitle={intlChangePasswordSuccess('subtitle')}
        submit={intlChangePasswordSuccess('submit')}
        Image={LockSuccess}
        onSubmit={onSubmit}
      />
    </Layout>
  )
}

export default SubjectsChangePasswordSuccessPage
export const Head = () => <title>Datacapt - Change Password</title>
